import { Router, Route, Switch } from "react-router-dom"
import { Container } from "reactstrap"

import Loading from "./components/Loading"
import NavBar from "./components/NavBar"
import Footer from "./components/Footer"
import Home from "./views/Home"
import history from "./utils/history"
import { useAppContext } from './components/AppContextProvider'

import "./App.css"
import initFontAwesome from "./utils/initFontAwesome"
import BackendData from "./views/BackendData"
initFontAwesome()

const App = () => {
  const { routes, error, isInitialized, logout } = useAppContext()

  if (error) {
    if (error.message === 'EMAIL_NOT_VALIDATED') {
      logout({
        returnTo: window.location.origin + '/verify'
      })
    } else {
      return <div>
        Oops... {error.message}
      </div>
    }
  }

  if (!isInitialized) {
    return <Loading />
  }

  const renderApiRoutes = () => routes
    .map(route => <Route key={route.path} path={route.path} render={
      () => <BackendData apiPath={route.path} />
    } />)

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar />
        <Container className="flex-grow-1 mt-5">
          <Switch>
            <Route path="/" exact component={Home} />
            {renderApiRoutes()}
          </Switch>
        </Container>
        <Footer />
      </div>
    </Router>
  )
}

export default App
