import {
  Box,
  Snackbar
} from '@mui/material'
import { CopyButton } from './CopyButton'
import { useState } from 'react'
import PropTypes from 'prop-types'

import styles from './TokenCopyButtons.module.scss'

const TokenCopyButtons = ({ isLoading, loadedTokens }) => {
  const [isCopiedTooltipVisible, setIsCopiedTooltipVisible] = useState(false)
  const skipRender = isLoading || !loadedTokens

  const onClick = (value) => {
    navigator.clipboard.writeText(value)
    setIsCopiedTooltipVisible(true)
  }

  return skipRender ?
    <></> :
    <Box className={styles.buttonsContainer} >
      <Snackbar
        open={isCopiedTooltipVisible}
        autoHideDuration={2000}
        ContentProps={{ className: styles.messageContent }}
        onClose={() => {
          setIsCopiedTooltipVisible(false)
        }}
        message='Value is copied to clipboard'
        className={styles.message}
      />
      <Box className={styles.copyButton}>
        <CopyButton tooltip='Copy id token' onClick={() => onClick(loadedTokens.id_token)}/>
      </Box>
      <Box className={styles.copyButton}>
        <CopyButton tooltip='Copy access token' onClick={() => onClick(loadedTokens.access_token)}/>
      </Box>
    </Box>
}

TokenCopyButtons.propTypes = {
  isLoading: PropTypes.bool,
  loadedTokens: PropTypes.shape({
    id_token: PropTypes.string,
    access_token: PropTypes.string
  })
}

export {
  TokenCopyButtons
}
