import { Tooltip, IconButton } from '@mui/material'
import PropTypes from "prop-types"
import CopyIcon from '@mui/icons-material/CopyAllOutlined'

const CopyButton = ({ onClick, tooltip }) => {
  return <Tooltip title={tooltip}>
    <IconButton onClick={onClick}>
      <CopyIcon style={{ color: "white", height: "25px", width: "25px" }}/>
    </IconButton>
  </Tooltip>
}

CopyButton.propTypes = {
  tooltip: PropTypes.string,
  onClick: PropTypes.func
}

export {
  CopyButton
}
