import { Fragment } from "react"

import HomePage from "../components/HomePage"

const Home = () => (
  <Fragment>
    <HomePage/>
  </Fragment>
)

export default Home
