import { Fragment, useCallback, useState } from 'react'
import Highlight from 'react-highlight'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Button,
  Switch,
  TextField,
  Paper,
  IconButton,
  FormControlLabel
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InfoIcon from '@mui/icons-material/Info'
import { useDecodedTokens } from '../../hooks/useDecodedTokens'
import { useForm } from '../../hooks/useForm'
import { getConfig } from "../../config"
import { TokenTesterRefreshAlert } from "./TokenTesterRefreshAlert"

import 'highlight.js/scss/solarized-dark.scss'
import styles from './TokenDetails.module.scss'
import { UseTokenCacheTooltip } from "./UseTokenCacheTooltip"
import { UseRefreshTokenTooltip } from "./UseRefreshTokensTooltip"
import { TokenTesterTooltip } from "./TokenTesterTooltip"
import { TokenCopyButtons } from './TokenCopyButtons'


const { audience, scope } = getConfig()

const TokenDetails = () => {
  const [useRefreshTokens, setUseRefreshTokens] = useState(localStorage.getItem('useRefreshTokens') === "true")
  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const [tokens, reloadTokens, isLoading] = useDecodedTokens()

  const [config, handleFormEdit] = useForm({
    audience,
    scope,
    cacheMode: 'off'
  })

  const onSubmitForm = useCallback(async () => {
    await reloadTokens({
      detailedResponse: true,
      authorizationParams: {
        audience: config.audience,
        ...!useRefreshTokens && {
          scope: config.scope
        },
        ...useRefreshTokens && {
          refresh_scope: config.scope
        }
      },
      cacheMode: config.cacheMode
    })
  }, [config, useRefreshTokens, reloadTokens])

  const changeFormData = useCallback(event => {
    event.preventDefault()
    event.persist()
    const fieldName = (event.target.id || event.target.name)
    const fieldValue = event.target.value
    handleFormEdit(fieldName, fieldValue)
  }, [handleFormEdit])

  const submitForm = useCallback(event => {
    event.preventDefault()
    event.stopPropagation()
    onSubmitForm().catch(error => console.error(error))
  }, [onSubmitForm])

  return <Paper>
    <TokenTesterRefreshAlert open={isAlertOpen} setOpen={setIsAlertOpen}/>
    <div className={styles.tokensContainer}>
      <form id="tokenTesterForm" onSubmit={submitForm}
        style={{ display: 'flex', flexDirection: 'column' }}>
        <TextField fullWidth
          disabled={useRefreshTokens}
          id="audience"
          helperText={'Audience'}
          value={config.audience}
          onChange={changeFormData}/>
        <TextField fullWidth
          id="scope"
          helperText={'Scope'}
          value={config.scope}
          onChange={changeFormData}/>
        <FormControlLabel className={styles.useCache} labelPlacement={"start"}
          label={
            <Fragment>
              <TokenTesterTooltip id="button-report" placement={"left-start"}
                title={<UseTokenCacheTooltip/>}>
                <IconButton>
                  <InfoIcon style={{ color: "#1565c0" }}/>
                </IconButton>
              </TokenTesterTooltip>
                            Use Tokens Cache
            </Fragment>
          }
          control={<Switch onChange={(event) => {
            event.target.value = event.target.checked === true ? 'on' : 'off'
            changeFormData(event)
          }} id={'cacheMode'}/>}/>
        <FormControlLabel className={styles.useCache} labelPlacement={"start"}
          label={
            <Fragment>
              <TokenTesterTooltip id="button-report" placement={"left-start"}
                title={<UseRefreshTokenTooltip/>}>
                <IconButton>
                  <InfoIcon style={{ color: "#1565c0" }}/>
                </IconButton>
              </TokenTesterTooltip>
              Use Refresh Tokens
            </Fragment>
          }
          control={<Switch checked={useRefreshTokens} onChange={(event) => {
            const useRefreshTokens = event.target.checked
            setUseRefreshTokens(useRefreshTokens)
            localStorage.setItem('useRefreshTokens', useRefreshTokens)
            setIsAlertOpen(true)
          }} id={'cacheMode'}/>}/>
        <div className={styles.reloadButtonContainer}>
          <Button type="submit" disabled={isLoading} form="tokenTesterForm"
            variant="contained">Reload</Button>
        </div>
      </form>
      <Accordion className={styles.tokenContainer}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography>Tokens raw response</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TokenCopyButtons isLoading={isLoading} loadedTokens={tokens.loadedTokens}/>
          <Highlight className={`json ${styles.profileContainer}`}>
            {JSON.stringify(tokens.loadedTokens, null, 2)}
          </Highlight>
        </AccordionDetails>
      </Accordion>
      <Accordion className={styles.tokenContainer}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography>Decoded ID Token</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Highlight className={`json ${styles.profileContainer}`}>
            {JSON.stringify(tokens.idToken, null, 2)}
          </Highlight>
        </AccordionDetails>
      </Accordion>
      <Accordion className={styles.tokensContainer}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography>Decoded Access Token</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Highlight className="json">
            {JSON.stringify(tokens.accessToken, null, 2)}
          </Highlight>
        </AccordionDetails>
      </Accordion>
    </div>
  </Paper>
}

export default TokenDetails
